/* Hilfsklasse für Fettdruck */
.bold {
    font-weight: bold;
}

/* großes Overlay für Cookie-Warnung */
#general_cookie_message {
    margin: auto;
    position: absolute;
    top: 10%; left: 10%;
    bottom: 10%; right: 10%;
    background-color: #000;
    color: #FFF;
    z-index:5000000;
}

#general_cookie_message-text{
    position: absolute;
    top: 50%;
    left: 50%;
    font-size: 50px;
    color: white;
    transform: translate(-50%,-50%);
    -ms-transform: translate(-50%,-50%);
}


/* Zahllink */
.js-link-zahllink {
    border: 1px solid red;
    font-weight: bold;
}

/* Amazon Pay Edit-Links */
#js-changeAZShipping, #js-changeAZPayment {
    cursor: pointer;
}

#js-start-zahlbutton {
    border: 5px solid red;
}
#js-start-novalnet {
    border: 5px solid red;
}
/* Amazon Pay Button in account_payment.php */
#js-payWithAmazonAccount #js-payWithAmazonDiv {
float: left;
}


